import React from 'react'
import styled from 'styled-components'
import { HeroImageTitle } from 'components/new/hero'
import { Helmet } from 'react-helmet'
import Button from 'components/button/button'
import { graphql, Link } from 'gatsby'
import Layout from 'components/layout'
import Content from 'components/new/content'
import JobsSearch from 'components/jobs-search'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'
import JobListingAlertsForm from 'components/forms/job-listing-alerts'
import H2 from 'components/new/typography/h2'
import H3 from 'components/new/typography/h3'
import H4 from 'components/new/typography/h4'
import P from 'components/new/typography/p'
import Ul from 'components/new/ul'
import Spacer from 'components/new/spacer'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import AlternatingGrid from 'components/alternating-grid'
import { FaCheckCircle } from '@react-icons/all-files/fa/FaCheckCircle'
import YouTube from 'components/youtube'
import AdditionalResources from 'components/new/additional-resources'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const ServiceCareersPage = props => {
  const {
    data: { heroImage, heroImageMobile, agTechProgramImage },
  } = props

  const heroImages = withArtDirection(getImage(heroImage), [
    {
      media: '(max-width: 899px)',
      image: getImage(heroImageMobile),
    },
  ])
  return (
    <Layout>
      <Helmet>
        <title>Service Career Path | Hutson Inc</title>
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Careers',
                'item': 'https://www.hutsoninc.com/careers/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Service Career Path',
                'item': 'https://www.hutsoninc.com/careers/service/',
              },
            ],
          })}
        </script>
      </Helmet>

      <Hero image={heroImages} title='Service Career Path' overlayOpacity={0} />

      <Content kind='full'>
        <TopGrid>
          <SearchCol>
            <JobsSearch />
          </SearchCol>
          <MainCol>
            <H2>About our service department</H2>
            <P>
              Hutson's service department is committed to keeping customers up and running—no matter
              what size equipment they're working with. Our shops see everything from walk-behind
              mowers and chainsaws to large tractors and combines come through the door. We offer
              roles specializing in agriculture equipment, lawn and garden equipment, or a hybrid
              role that works on both.
            </P>
            <Spacer size='m' />
            <H3>Hutson's commitment to you</H3>
            <P>
              Hutson's technicians are some of the best in the industry. As such, Hutson wants to
              support you in furthering your career. New technicians can take advantage of Hutson’s
              Tool Support Program to assist in the purchase and maintenance of tools. We will also
              invest in your continued learning and development through John Deere University and
              training programs offered by STIHL and other shortlines we carry.
            </P>
            <P>Here's what you can expect when becoming a Hutson technician:</P>
            <BenefitsList>
              <li>
                <strong>$1,000/year Tool Allowance</strong>: Get $1,000 per year to add to your
                kit—plus, they're yours to keep!
              </li>
              <li>
                <strong>Pay for Performance</strong>: Get compensated when you put forth the extra
                effort.
              </li>
              <li>
                <strong>Employee Referral Bonus up to $4,000</strong>: Get rewarded if you refer
                someone that makes a great addition to our team.
              </li>
              <li>
                <strong>Continuous Training</strong>: Stay up to date on the latest technology with
                training and certification programs.
              </li>
              <li>
                <strong>Opportunities for Growth</strong>: Climb the ranks in your career as you
                gain experience.
              </li>
            </BenefitsList>
            <Spacer size='m' />
            <ButtonContainer>
              <Button as={Link} to='/careers/service/jobs/' ghost color='green'>
                Browse Service positions
              </Button>
            </ButtonContainer>
          </MainCol>
        </TopGrid>
      </Content>
      <LightSection>
        <Content kind='full'>
          <H2>Service career path</H2>
          <P>
            No prior experience? No problem! Jump start your career with the{' '}
            <Link to='/careers/tech-program/'>John Deere Tech Program</Link>.
          </P>
          <Spacer size='m' />
          <LevelContainer sc-level={1}>
            <div>
              <LevelHeader>Level 1</LevelHeader>
              <LevelHeaderAppend>0-3 years of experience</LevelHeaderAppend>
            </div>
            <P>
              <strong>Entry level</strong> technician with limited or no prior experience.
            </P>
            <Ul>
              <li>
                Requires a combination of basic knowledge and manual skill typically acquired
                through training or apprenticeship.
              </li>
              <li>
                Works under close supervision according to explicit instructions and established
                protocols and procedures.
              </li>
            </Ul>
            <P>
              <a href='#required-classes'>View required classes and training ›</a>
            </P>
          </LevelContainer>
          <Spacer size='xxs' />
          <LevelContainer sc-level={2}>
            <div>
              <LevelHeader>Level 2</LevelHeader>
              <LevelHeaderAppend>3-5 years of experience</LevelHeaderAppend>
            </div>
            <P>
              <strong>Intermediate level</strong> technician who has basic knowledge and prior
              experience.
            </P>
            <Ul>
              <li>
                Completes straightforward assignments according to instructions and established
                procedures with minimal supervision.
              </li>
              <li>
                Adds knowledge, skills and proficiency in work area, and builds competence in the
                use of computers and technical equipment to complete assigned duties.
              </li>
            </Ul>
            <P>
              Must be an expert in diagnosing and repairing 3 of the{' '}
              <a href='#equipment-types'>types of equipment</a>. Labor efficiency of 95% or higher
              will help determine expert level. Ranking determined by Location Manager &amp;
              Regional Manager with Aftermarket VP approval.
            </P>
            <P>
              <a href='#required-classes'>View required classes and training ›</a>
            </P>
          </LevelContainer>
          <Spacer size='xxs' />
          <LevelContainer sc-level={3}>
            <div>
              <LevelHeader>Level 3</LevelHeader>
              <LevelHeaderAppend>5-8 years of experience</LevelHeaderAppend>
            </div>
            <P>
              <strong>Senior level</strong> technician that is experienced, fully competent, works
              independently and receives minimal or no direction.
            </P>
            <Ul>
              <li>
                Identifies opportunities to improve quality or reliability and contributes to
                product, process or service improvements.
              </li>
              <li>Studies, recommends and implements new technologies and methods.</li>
              <li>
                Coaches and provides informal guidance to less-experienced staff, but is not a
                formal manager or supervisor.
              </li>
            </Ul>
            <P>
              Must be an expert in diagnosing and repairing 4 of the{' '}
              <a href='#equipment-types'>types of equipment</a>. Labor efficiency of 95% or higher
              will help determine expert level. Ranking determined by Location Manager &amp;
              Regional Manager with Aftermarket VP approval.
            </P>
            <P>
              <a href='#required-classes'>View required classes and training ›</a>
            </P>
          </LevelContainer>
          <Spacer size='xxs' />
          <LevelContainer sc-level={4}>
            <div>
              <LevelHeader>Level 4</LevelHeader>
              <LevelHeaderAppend>8+ years of experience</LevelHeaderAppend>
            </div>
            <P>
              <strong>Specialist level</strong> technician who applies extensive skills, knowledge,
              and experience to specialized areas and complex tasks.
            </P>
            <Ul>
              <li>
                May lead project teams and provide training and guidance to less-experience
                technicians, but is not a formal manager or supervisor.
              </li>
              <li>
                Works on the same tasks as other team members, but typically more advanced tasks are
                assigned.
              </li>
              <li>Acquires knowledge of advanced technologies and methods.</li>
              <li>
                Applies experience in specialized areas to research complex problems and make
                recommendations to achieve objectives.
              </li>
            </Ul>
            <P>
              Must be an expert in diagnosing and repairing 6 of the{' '}
              <a href='#equipment-types'>types of equipment</a>. Labor efficiency of 95% or higher
              will help determine expert level. Ranking determined by Location Manager &amp;
              Regional Manager with Aftermarket VP approval.
            </P>
            <P>
              <a href='#required-classes'>View required classes and training ›</a>
            </P>
          </LevelContainer>
          <Spacer size='l' />
          <H4 as='div' id='equipment-types'>
            Types of equipment
          </H4>
          <EquipmentList>
            <li>Compact Tractors</li>
            <li>Utility Tractors</li>
            <li>Row Crop Tractors</li>
            <li>4 Wheel Drive Tractors</li>
            <li>Harvesting Equipment</li>
            <li>Planting/Seeding Equipment</li>
            <li>Sprayers/Dry Box</li>
            <li>Hay Equipment</li>
            <li>Compact Construction Equipment</li>
            <li>AMS</li>
            <li>Turf/Handheld Equipment</li>
          </EquipmentList>
          <Spacer size='l' />
          <H3 id='required-classes'>Required classes and training</H3>
          <Spacer size='m' />
          <Table>
            <thead>
              <tr>
                <td></td>
                <td>
                  <TableLevelBanner sc-level={1}>Entry</TableLevelBanner>
                  <TableHeaderInner>
                    <H4>Level 1</H4>
                    <TableLevelSubheader>0-3 years of experience</TableLevelSubheader>
                  </TableHeaderInner>
                </td>
                <td>
                  <TableLevelBanner sc-level={2}>Intermediate</TableLevelBanner>
                  <TableHeaderInner>
                    <H4>Level 2</H4>
                    <TableLevelSubheader>3-5 years of experience</TableLevelSubheader>
                  </TableHeaderInner>
                </td>
                <td>
                  <TableLevelBanner sc-level={3}>Senior</TableLevelBanner>
                  <TableHeaderInner>
                    <H4>Level 3</H4>
                    <TableLevelSubheader>5-8 years of experience</TableLevelSubheader>
                  </TableHeaderInner>
                </td>
                <td>
                  <TableLevelBanner sc-level={4}>Specialist</TableLevelBanner>
                  <TableHeaderInner>
                    <H4>Level 4</H4>
                    <TableLevelSubheader>8+ years of experience</TableLevelSubheader>
                  </TableHeaderInner>
                </td>
              </tr>
              <tr>
                <td>Classes must be completed within</td>
                <td>
                  <MobileLabel>Level 1</MobileLabel> 1 year
                </td>
                <td>
                  <MobileLabel>Level 2</MobileLabel> 3-5 years
                </td>
                <td>
                  <MobileLabel>Level 3</MobileLabel> 5-8 years
                </td>
                <td>
                  <MobileLabel>Level 4</MobileLabel> 8-10 years
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Electrical Core</th>
                <td>
                  <MobileLabel>Level 1</MobileLabel>
                  <Check />
                </td>
                <td>
                  <MobileLabel>Level 2</MobileLabel>
                  <Check />
                </td>
                <td>
                  <MobileLabel>Level 3</MobileLabel>
                  <Check />
                </td>
                <td>
                  <MobileLabel>Level 4</MobileLabel>
                  <Check />
                </td>
              </tr>
              <tr>
                <th>Hydraulic Core</th>
                <td>
                  <MobileLabel>Level 1</MobileLabel>
                  <Check />
                </td>
                <td>
                  <MobileLabel>Level 2</MobileLabel>
                  <Check />
                </td>
                <td>
                  <MobileLabel>Level 3</MobileLabel>
                  <Check />
                </td>
                <td>
                  <MobileLabel>Level 4</MobileLabel>
                  <Check />
                </td>
              </tr>
              <tr>
                <th>Service Advisor</th>
                <td>
                  <MobileLabel>Level 1</MobileLabel>
                  <Check />
                </td>
                <td>
                  <MobileLabel>Level 2</MobileLabel>
                  <Check />
                </td>
                <td>
                  <MobileLabel>Level 3</MobileLabel>
                  <Check />
                </td>
                <td>
                  <MobileLabel>Level 4</MobileLabel>
                  <Check />
                </td>
              </tr>
              <tr>
                <th>Core Certification</th>
                <td>
                  <MobileLabel>Level 1</MobileLabel>
                  <Check />
                </td>
                <td>
                  <MobileLabel>Level 2</MobileLabel>
                  <Check />
                </td>
                <td>
                  <MobileLabel>Level 3</MobileLabel>
                  <Check />
                </td>
                <td>
                  <MobileLabel>Level 4</MobileLabel>
                  <Check />
                </td>
              </tr>
              <tr>
                <th>Technical Foundations Learning Path</th>
                <td>
                  <MobileLabel>Level 1</MobileLabel>
                </td>
                <td>
                  <MobileLabel>Level 2</MobileLabel>
                  <Check />
                </td>
                <td>
                  <MobileLabel>Level 3</MobileLabel>
                  <Check />
                </td>
                <td>
                  <MobileLabel>Level 4</MobileLabel>
                  <Check />
                </td>
              </tr>
              <tr>
                <th>Operations Center Certification Level 1</th>
                <td>
                  <MobileLabel>Level 1</MobileLabel>
                </td>
                <td>
                  <MobileLabel>Level 2</MobileLabel>
                  <Check />
                </td>
                <td>
                  <MobileLabel>Level 3</MobileLabel>
                  <Check />
                </td>
                <td>
                  <MobileLabel>Level 4</MobileLabel>
                  <Check />
                </td>
              </tr>
              <tr>
                <th>System Diagnostics Learning Path</th>
                <td>
                  <MobileLabel>Level 1</MobileLabel>
                </td>
                <td>
                  <MobileLabel>Level 2</MobileLabel>
                </td>
                <td>
                  <MobileLabel>Level 3</MobileLabel>
                  <Check />
                </td>
                <td>
                  <MobileLabel>Level 4</MobileLabel>
                  <Check />
                </td>
              </tr>
              <tr>
                <th>Engine Diagnostics Certification FT4</th>
                <td>
                  <MobileLabel>Level 1</MobileLabel>
                </td>
                <td>
                  <MobileLabel>Level 2</MobileLabel>
                </td>
                <td>
                  <MobileLabel>Level 3</MobileLabel>
                  <Check />
                </td>
                <td>
                  <MobileLabel>Level 4</MobileLabel>
                  <Check />
                </td>
              </tr>
              <tr>
                <th>Operations Center Certification Level 2</th>
                <td>
                  <MobileLabel>Level 1</MobileLabel>
                </td>
                <td>
                  <MobileLabel>Level 2</MobileLabel>
                </td>
                <td>
                  <MobileLabel>Level 3</MobileLabel>
                </td>
                <td>
                  <MobileLabel>Level 4</MobileLabel>
                  <Check />
                </td>
              </tr>
            </tbody>
          </Table>
          <Spacer size='l' />
          <H3>Leadership and advancement opportunities</H3>
          <P>
            More opportunities may become available as you advance in the service department. If
            you're a team builder and a leader, a Service Manager role or other leadership position
            may be your next step. You may also decide to become a road technician or transition
            into a Precision Ag role. The choice is yours!
          </P>
        </Content>
      </LightSection>
      <Content kind='full'>
        <YouTube videoId='mY4dALmp5hk' />
        <Spacer size='xl' />
        <H2>Take the next step</H2>
        <P>
          Interested in working for Hutson? We hope you take some time to view our current career
          opportunities and apply! If you have any questions, reach out to our team at{' '}
          <a href='mailto:recruiting@hutsoninc.com'>recruiting@hutsoninc.com</a>.
        </P>
        <Spacer size='m' variable />
        <ButtonContainer>
          <Button as={Link} to='/careers/service/jobs/' ghost color='green'>
            Browse Service positions
          </Button>
        </ButtonContainer>
      </Content>
      <AgTechProgramGrid
        direction='ltr'
        content={[
          <GatsbyImage
            image={getImage(agTechProgramImage)}
            alt=''
            objectFit='cover'
            objectPosition='50% 100%'
            style={{ height: '100%', width: '100%' }}
          />,
          <AgTechProgramText>
            <H2>John Deere Tech Program</H2>
            <P>
              Get a head start in your career with the Tech Program. It's a two-year associates
              degree being offered by top-notch schools like Vincennes University and Lake Land
              College. You'll learn about servicing, repairing, and rebuilding ag and turf equipment
              that keep Hutson customers going.
            </P>
            <Spacer size='m' variable />
            <ButtonContainer>
              <Button as={Link} to='/careers/tech-program/' ghost color='green'>
                Learn more about the Tech Program
              </Button>
            </ButtonContainer>
          </AgTechProgramText>,
        ]}
      />
      <JobListingAlertsForm />
      <AdditionalResources
        resources={[
          {
            title: 'Careers',
            links: [
              <Link to='/careers/'>Careers at Hutson</Link>,
              <Link to='/careers/jobs/'>Browse open positions</Link>,
            ],
          },
          {
            title: 'About Us',
            links: [
              <Link to='/about/'>About us</Link>,
              <Link to='/locations/'>Our locations</Link>,
              <Link to='/contact/'>Contact us</Link>,
            ],
          },
          {
            title: 'Disclosures',
            links: [
              <OutboundLink href='https://www.anthem.com/machine-readable-file/search/'>
                Transparency in coverage
              </OutboundLink>,
            ],
          },
        ]}
      />
    </Layout>
  )
}

const Table = styled.table`
  border: none;
  border-collapse: collapse;
  color: ${props => props.theme.color.n900};
  width: 100%;

  td,
  th,
  tr {
    padding: 0;
  }

  @media (max-width: 899px) {
    thead {
      tr {
        :first-child {
          display: none;
        }

        :last-child {
          margin-bottom: ${props => props.theme.size.m};

          td {
            background-color: #fff;
            display: flex;
            border-top: ${props => `1px solid ${props.theme.color.n30}`};
            position: relative;

            :first-child {
              background-color: ${props => props.theme.color.n10};
              font-weight: 600;
              text-align: left;
            }
          }
        }
      }
    }

    tbody {
      tr {
        border: ${props => `1px solid ${props.theme.color.n30}`};

        :not(:last-child) {
          margin-bottom: ${props => props.theme.size.m};
        }

        th {
          background-color: ${props => props.theme.color.n10};
          font-weight: 600;
          text-align: left;
        }

        td {
          background-color: #fff;
          display: flex;
          border-top: ${props => `1px solid ${props.theme.color.n30}`};
          padding: ${props => props.theme.size.s};
          position: relative;
        }
      }
    }

    th,
    td {
      padding: ${props => props.theme.size.s};
    }

    tr,
    th,
    td {
      display: block;
      width: 100%;
    }
  }

  @media (min-width: 900px) {
    background-color: #fff;
    border: ${props => `1px solid ${props.theme.color.n30}`};

    th,
    td {
      border: ${props => `1px solid ${props.theme.color.n30}`};
      padding: ${props => props.theme.size.s};
    }

    tr {
      td {
        :first-child {
          width: 330px;
        }
      }
    }

    thead {
      text-align: center;

      h4 {
        text-align: center;
      }

      tr {
        :first-child {
          td {
            padding: 0;
          }
        }

        :last-child {
          background-color: ${props => props.theme.color.n700};
          color: #fff;
          font-size: 14px;
          font-weight: 600;

          td {
            padding: 8px 12px;

            :first-child {
              padding: 8px 16px;
              text-align: left;
            }
          }
        }
      }
    }

    tbody {
      th {
        font-weight: 400;
        text-align: left;
      }

      td {
        text-align: center;
      }

      tr {
        :nth-child(odd) {
          background-color: ${props => props.theme.color.n10};
        }
      }
    }
  }
`

const TableLevelBanner = styled.div`
  background-color: ${props => {
    switch (props['sc-level']) {
      case 1:
        return props.theme.color.y100
      case 2:
        return props.theme.color.y200
      case 3:
        return props.theme.color.y300
      default:
      case 4:
        return props.theme.color.y400
    }
  }};
  color: ${props => {
    switch (props['sc-level']) {
      case 4:
        return props.theme.color.y700
      default:
        return props.theme.color.y600
    }
  }};
  font-weight: 600;
  padding: ${props => props.theme.size.xs};
`

const TableHeaderInner = styled.div`
  padding: ${props => `${props.theme.size.s} ${props.theme.size.xxs}`};
`

const TableLevelSubheader = styled.span`
  color: ${props => props.theme.color.n700};
  font-size: 12px;
`

const MobileLabel = styled.span`
  flex-grow: 2;

  @media (min-width: 900px) {
    display: none;
  }
`

const Check = () => {
  return <StyledCheck aria-hidden='true' focusable='false' role='presentation' />
}

const StyledCheck = styled(FaCheckCircle)`
  color: ${props => props.theme.color.g400};
  font-size: 21px;
`

const AgTechProgramGrid = styled(AlternatingGrid)`
  background-color: ${props => props.theme.color.n20};
`

const AgTechProgramText = styled.div`
  direction: ltr;
  padding: 24px;

  @media (min-width: 900px) {
    padding: 24px 48px;
  }
`

const LevelContainer = styled.div`
  background-color: #fff;
  border-left: 8px solid
    ${props => {
      switch (props['sc-level']) {
        case 1:
          return props.theme.color.y100
        case 2:
          return props.theme.color.y200
        case 3:
          return props.theme.color.y300
        default:
        case 4:
          return props.theme.color.y400
      }
    }};
  padding: 24px;
`

const LevelHeader = styled(H3)`
  display: inline-block;
  margin-right: ${props => props.theme.size.xs};
`

const LevelHeaderAppend = styled.span`
  color: ${props => props.theme.color.n700};
  display: inline-block;
  font-size: 14px;
  font-style: italic;
`

const LightSection = styled.div`
  background-color: ${props => props.theme.color.n20};
`

const Hero = styled(HeroImageTitle)`
  height: 300px;

  @media (min-width: 900px) {
    height: 450px;
  }
`

const BenefitsList = styled(Ul)`
  @media (min-width: 900px) {
    columns: 2;
    column-gap: ${props => props.theme.size.m};
  }
`

const EquipmentList = styled(Ul)`
  @media (min-width: 480px) {
    columns: 2;
    column-gap: ${props => props.theme.size.m};
  }

  @media (min-width: 900px) {
    columns: 4;
  }
`

const ButtonContainer = styled.div`
  a {
    display: inline-block;
    text-decoration: none;
  }
`

const TopGrid = styled.div`
  @media (min-width: 900px) {
    ${clearfix}
    direction: rtl;
  }
`

const MainCol = styled.div`
  margin-top: 40px;

  @media (min-width: 900px) {
    ${column('2/3', 0)}
    direction: ltr;
    display: inline-block;
    float: none !important;
    margin-top: 0;
    padding: 25px 30px 0 0;
    vertical-align: top;
  }

  @media (min-width: 1200px) {
    ${column('3/4', 0)}
  }
`

const SearchCol = styled.div`
  @media (min-width: 900px) {
    ${column('1/3', 0)}
    direction: ltr;
    display: inline-block;
    float: none !important;
    vertical-align: top;
  }

  @media (min-width: 1200px) {
    ${column('1/4', 0)}
  }
`

export const pageQuery = graphql`
  {
    heroImage: file(relativePath: { eq: "careers/service-hero.jpg" }) {
      ...FullWidthImage
    }
    heroImageMobile: file(relativePath: { eq: "careers/service-hero-mobile.jpg" }) {
      ...FullWidthImage
    }
    agTechProgramImage: file(relativePath: { eq: "careers/ag-tech-program.jpg" }) {
      ...SharpImage800
    }
  }
`

export default ServiceCareersPage
